const listOfNames = {
    id: "id",
    name: "Nombre",
    email: "Correo",
    country: "Pais",
    so: "Sistema Operativo",
    platform: "Plataforma",
    brand: "Marca",
    date_created: "Fecha de creacion",
    premium: "¿Es Premium?",
    email_valid: "¿Email valido?",
    facebook_id: "Facebook ID",
    was_premium: "¿Fue Premium?",
    true: "Si",
    false: "No",
    song_count: "Canciones cantadas",
    cant_days: "Dias de uso del app Smart TV",
    connect: "Veces que conecto su celular al Smart TV",
    cant_days_web: "Dias de uso del app Web",
    connect_web: "Veces que conecto su celular al app Web",
    cant_open_session: "Veces que inicio sesion",
    dates_open_session: "Fechas de inicio de sesion",
    plan_intent_pay: "Plan que intento pagar",
    plan_web: "Plan web que intento pagar",
    card: "Tarjeta",
    last4: "Ultimos 4 digitos",
    month_card: "Mes de vencimiento",
    year_card: "Año de vencimiento",
    token: "Token",
    payment: "Pago",
    canceled: "Cancelado",
    created: "Creado",
    currency: "Moneda",
    end_subscription: "Fin de suscripción",
    next_invoice: "Siguiente Boleta",
    next_payment: "Siguiente Pago",
    plan: "Plan",
    price: "Precio",
    status: "Estado",
    trial_period_days: "Dias de periodo de prueba",
    subscriptions: "Suscripciones"
}
export const naming = name => {
    if (listOfNames[name] !== undefined) return listOfNames[name]
    else return name;
}