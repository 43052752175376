import './info.css';
import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import Error from "./Error";
import Preloader from "./Preloader";
import { naming } from "./naming";
import { JsonToTable } from "react-json-to-table";

class Subscription extends Component {
  render() {
    const { subscription, error } = this.props;
    if (error !== undefined && error.isError && error.type === "subscription") {
      return (
        <div className="to-do-list-container">
          <div className="row">
            <Error from={error.from} status={error.status} message={error.message} />
          </div>
        </div>
      );
    }
    if (subscription === "loading") {
      return (
        <div className="row center-align">
          <div className="col s4 offset-s4">
            <Preloader />
          </div>
        </div>
      );
    }
    const userSubscription = (subscription) => {
      var resultJson = {};
      Object.keys(subscription).forEach(key => {
        const value = subscription[key];
        const newKey = naming(key);
        var newValue = value !== null && value !== undefined ? value : "";
        if (typeof newValue === "object") {
          var subResult = {}, subResultSubscription = [];
          Object.keys(newValue).forEach(subKey => {
            const subValue = newValue[subKey];
            const newSubKey = naming(subKey);
            var newSubValue = subValue !== null && subValue !== undefined ? subValue : "";
            if (typeof newSubValue === "object") {//&& Array.isArray(newSubValue)
              var subSubResult = {};
              var newSubValue2 = newSubValue;
              Object.keys(newSubValue2).forEach((subSubKey) => {
                const subSubValue = newSubValue2[subSubKey];
                const newSubSubKey = naming(subSubKey);
                var newSubValue = subSubValue !== null && subSubValue !== undefined ? subSubValue : "";
                if (subSubKey === "card") newSubValue = newSubValue.card_first_six_digits !== undefined ? newSubValue.card_first_six_digits + '***' + newSubValue.card_last_four_digits + '--' + newSubValue.card_month + '/' + newSubValue.card_year + '--' + newSubValue.card_type : "-"
                else newSubValue = naming(newSubValue.toString());
                subSubResult[newSubSubKey] = newSubValue;
              });
              newSubValue = subSubResult;
            } else newSubValue = newSubValue.toString();
            if (key === "subscriptions") {
              subResultSubscription.push(newSubValue);
              subResult = subResultSubscription;
            } else subResult[newSubKey] = newSubValue;
          });
          newValue = subResult;
        } else newValue = newValue.toString();
        resultJson[newKey] = newValue;
      });
      return resultJson;
    }
    const userSubscriptionJson = userSubscription(subscription);
    if (!_.isEmpty(userSubscriptionJson) && subscription !== "" && subscription.subscriptions !== undefined && subscription.subscriptions.length > 0) {
      const tableResult =
        <table className="subscription-table">
          <thead>
            <tr>
              <th>Suscripciones</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th><JsonToTable json={userSubscriptionJson} /></th>
            </tr>

          </tbody>
        </table>;
      return tableResult;
    } else if (!_.isEmpty(userSubscriptionJson) && subscription !== "") return <div>El usuario no tiene suscripciones.</div>;
    return (
      <div></div>
    );
  }
}

const mapStateToProps = ({ subscription, error }) => {
  return {
    subscription,
    error
  };
};

export default connect(mapStateToProps, null)(Subscription);