import "../index.css";
import "./ToDoList.css";
import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import M from "materialize-css/dist/js/materialize.min.js";

class ListSubscriptions extends Component {
    componentDidMount() {
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems, {});
    }
    componentDidUpdate() {
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems, {});
    }
    render() {
        const { value, handleSelectChange, subscriptions } = this.props;
        const onlySubscriptions = subscriptions.subscriptions ? subscriptions.subscriptions : [];
        const idList = _.map(onlySubscriptions, (value, key) => {
            return <option key={key + Math.floor(Math.random() * 100)} value={value.id}>{value.id}</option>;
        });

        if (!_.isEmpty(idList)) {
            const result =
                <div className="input-field">
                    <i className="material-icons prefix">account_balance</i>
                    <select
                        value={value}
                        onChange={handleSelectChange}
                        id="idSubscription"
                    >
                        <option value="" disabled>Seleccione un ID</option>
                        {idList}
                    </select>
                    <label htmlFor="idSubscription">ID de Suscripcion</label>
                </div>;
            return result;
        }
        return (
            <div></div>
        );
    }
}

export default connect(null, null)(ListSubscriptions);