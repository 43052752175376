export const FETCH_CARD = "FETCH_CARD";
export const LOADING_CARD = "LOADING_CARD";
export const FETCH_USER = "FETCH_USER";
export const LOADING_TOKEN = "LOADING_TOKEN";
export const FETCH_TOKEN = "FETCH_TOKEN";
export const SHOW_STATUS_ACTIONS = "SHOW_STATUS_ACTIONS";
export const HIDE_STATUS_ACTIONS = "HIDE_STATUS_ACTIONS";
export const SHOW_ERROR = "SHOW_ERROR";
export const HIDE_ERROR = "HIDE_ERROR";
export const FETCH_STATISTICS = "FETCH_STATISTICS";
export const FETCH_SUBSCRIPTION = "FETCH_SUBSCRIPTION";
export const LOADING_USER = "LOADING_USER";
export const LOADING_STATISTICS = "LOADING_STATISTICS";
export const LOADING_SUBSCRIPTION = "LOADING_SUBSCRIPTION";
export const LOADING_CANCEL_SUBSCRIPTION = "LOADING_CANCEL_SUBSCRIPTION";
export const LOADING_RESET_PASSWORD = "LOADING_RESET_PASSWORD";
export const LOADING_SEND_EMAIL_SAFETYPAY = "LOADING_SEND_EMAIL_SAFETYPAY";
export const LOADING_CANCEL_SUBSCRIPTION_DEFINITELY = "LOADING_CANCEL_SUBSCRIPTION_DEFINITELY";
export const LOADING_CANCEL_SUBSCRIPTION_30_DAYS = "LOADING_CANCEL_SUBSCRIPTION_30_DAYS";
export const LOADING_SEND_EMAIL_CANCEL_SUBSCRIPTION = "LOADING_SEND_EMAIL_CANCEL_SUBSCRIPTION";
export const LOADING_CHANGE_EMAIL = "LOADING_CHANGE_EMAIL";
export const LOADING_CHANGE_PLAN = "LOADING_CHANGE_PLAN";
export const LOADING_UPGRADE_PREMIUM_SAFETYPAY = "LOADING_UPGRADE_PREMIUM_SAFETYPAY";