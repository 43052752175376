// index.js
import * as TYPE from './types';
import axios from 'axios';
import store from '../Store';

const apiUrl = 'https://api-customer-services.karaokesmart.co/v1';

export const fetchCard = (card) => {
  store.dispatch({
    type: TYPE.LOADING_CARD
  })
  return (dispatch) => {
    var profile = {};
    profile['last_four_card'] = card;
    return axios.post(`${apiUrl}/search/card/`, profile, {
      headers: {
        "content-type": "application/json",
        "platform": "Web",
        "device": "customer service"
      }
    })
      .then(response => {
        dispatch({
          type: TYPE.FETCH_CARD,
          payload: response.data.data
        })
      })
      .catch(error => {
        fetchError(dispatch, error, 'card', 'Recibiendo las tarjetas');
      });
  };
};
export const fetchToken = (email) => {
  store.dispatch({
    type: TYPE.LOADING_TOKEN
  })

  return (dispatch) => {
    var profile = {};
    profile['email'] = email;
    return axios.post(`${apiUrl}/search/user/`, profile, {
      headers: {
        "content-type": "application/json",
        "platform": "Web",
        "device": "customer service"
      }
    })
      .then(response => {
        dispatch({
          type: TYPE.FETCH_TOKEN,
          payload: response.data.data.users
        })
      })
      .catch(error => {
        fetchError(dispatch, error, 'token', 'Recibiendo token del usuario');
      });
  };
};
export const fetchUser = (token) => {
  store.dispatch({
    type: TYPE.LOADING_USER
  })
  const authorization = 'token ' + token;
  return (dispatch) => {
    return axios.get(`${apiUrl}/user/`, {
      headers: {
        "content-type": "application/json",
        "Authorization": authorization,
        "platform": "Web",
        "device": "customer service"
      }
    }).then(response => {
      dispatch({
        type: TYPE.FETCH_USER,
        payload: { ...response.data.data, token }
      })
    })
      .catch(error => {
        fetchError(dispatch, error, 'user', 'Informacion del usuario');
      });
  };
};


export const fetchSubscription = (token) => {
  store.dispatch({
    type: TYPE.LOADING_SUBSCRIPTION
  })
  const authorization = 'token ' + token;
  return (dispatch) => {
    return axios.get(`${apiUrl}/user/subscription/`, {
      headers: {
        "content-type": "application/json",
        "Authorization": authorization,
        "platform": "Web",
        "device": "customer service"
      }
    }).then(response => {
      dispatch({
        type: TYPE.FETCH_SUBSCRIPTION,
        payload: response.data.data
      })
    })
      .catch(error => {
        fetchError(dispatch, error, 'subscription', 'Suscripcion del usuario');
      });
  };
};
export const fetchError = (dispatch, error, type, from) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    dispatch({
      type: TYPE.SHOW_ERROR,
      payload: { type, from, status: 'Error ' + error.response.status, message: error.response.data.detail }
    })

  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    dispatch({
      type: TYPE.SHOW_ERROR,
      payload: { type, from, status: 'Error', message: error.request }
    })

  } else { // Something happened in setting up the request that triggered an Error
    dispatch({
      type: TYPE.SHOW_ERROR,
      payload: { type, from, status: 'Error', message: error.message }
    })

  }
}
export const hideError = () => {
  return (dispatch) =>
    dispatch({
      type: TYPE.HIDE_ERROR
    });
}
const verifyReasons = (reasons) => {
  var ret = true;
  for (var i = 0; i < reasons.length; i++) {
    if (reasons[i].length > 200) {
      ret = false;
      break;
    }
  }
  return ret;
}
//especificar que los mensajes deben de tener tipo, from, status, message, etc
export const cancelSubscription = (token, reasons) => {
  store.dispatch({
    type: TYPE.LOADING_CANCEL_SUBSCRIPTION
  })
  const authorization = 'token ' + token;
  //error.response.status,message:error.response.data.detail
  const array_reasons = reasons.split(',');
  if (!verifyReasons(array_reasons)) {
    var myError = {
      response: {
        status: 'Error',
        data: {
          detail: 'Razones deben ser de maximo 200 caracteres cada una.'
        }
      }
    }
    return (dispatch) => {
      fetchStatusActions("error", dispatch, myError, 'cancel_subscription', 'Cancelacion de Suscripcion');
    }
  }
  const payload = {
    "cancel_now": false,
    "additional_days": false,
    "source": "Customer",
    "reasons": array_reasons
  };
  return (dispatch) => {
    return axios.delete(`${apiUrl}/subscription`, {
      data: payload,
      headers: {
        "content-type": "application/json",
        "platform": "Web",
        "device": "customer service",
        "Authorization": authorization,
      }
    }).then(response => {
      console.log('response CANCEL subscription', response);
      if (response.data.success) fetchStatusActions("success", dispatch, response.data, 'cancel_subscription', 'Cancelacion de Suscripcion')
      else fetchStatusActions("error", dispatch, response.data, 'cancel_subscription', 'Cancelacion de Suscripcion');
    })
      .catch(error => {
        fetchStatusActions("error", dispatch, error, 'cancel_subscription', 'Cancelacion de Suscripcion');
      });
  };
};
export const resetPassword = (token) => {
  store.dispatch({
    type: TYPE.LOADING_RESET_PASSWORD
  })
  const authorization = 'token ' + token;
  return (dispatch) => {
    return axios.get(`${apiUrl}/user/password/`, {
      headers: {
        "content-type": "application/json",
        "Authorization": authorization,
        "platform": "Web",
        "device": "customer service"
      }
    }).then(response => {
      if (response.data.success) fetchStatusActions("success", dispatch, response.data.data, 'reset_password', 'Reset Password')
      else fetchStatusActions("error", dispatch, response.data, 'reset_password', 'Reset Password');
    })
      .catch(error => {
        fetchStatusActions("error", dispatch, error, 'reset_password', 'Reset Password');
      });
  };
};


export const cancelSubscriptionDefinitely = (token, reasons) => {
  store.dispatch({
    type: TYPE.LOADING_CANCEL_SUBSCRIPTION_DEFINITELY
  })
  const authorization = 'token ' + token;
  const array_reasons = reasons.split(',');
  if (!verifyReasons(array_reasons)) {
    var myError = {
      response: {
        status: 'Error',
        data: {
          detail: 'Razones deben ser de maximo 200 caracteres cada una.'
        }
      }
    }
    return (dispatch) => {
      fetchStatusActions("error", dispatch, myError, 'cancel_subscription_definitely', 'Cancelacion de Suscripcion Definitivamente');
    }
  }
  const payload = {
    "cancel_now": true,
    "additional_days": false,
    "source": "Customer",
    "reasons": array_reasons
  };
  return (dispatch) => {
    return axios.delete(`${apiUrl}/subscription`, {
      data: payload,
      headers: {
        "content-type": "application/json",
        "platform": "Web",
        "device": "customer service",
        "Authorization": authorization,
      }
    }).then(response => {
      console.log('response CANCEL subscription DEFINITELY', response);
      if (response.data.success) fetchStatusActions("success", dispatch, response.data, 'cancel_subscription_definitely', 'Cancelacion de Suscripcion Definitivamente')
      else fetchStatusActions("error", dispatch, response.data, 'cancel_subscription_definitely', 'Cancelacion de Suscripcion Definitivamente');
    })
      .catch(error => {
        fetchStatusActions("error", dispatch, error, 'cancel_subscription_definitely', 'Cancelacion de Suscripcion Definitivamente');
      });
  };
};
export const cancelSubscription30days = (token, reasons) => {
  store.dispatch({
    type: TYPE.LOADING_CANCEL_SUBSCRIPTION_30_DAYS
  })
  const authorization = 'token ' + token;
  const array_reasons = reasons.split(',');
  if (!verifyReasons(array_reasons)) {
    var myError = {
      response: {
        status: 'Error',
        data: {
          detail: 'Razones deben ser de maximo 200 caracteres cada una.'
        }
      }
    }
    return (dispatch) => {
      fetchStatusActions("error", dispatch, myError, 'cancel_subscription_30_days', 'Cancelacion de Suscripcion a 30 Dias');
    }
  }
  const payload = {
    "cancel_now": false,
    "additional_days": true,
    "source": "Customer",
    "reasons": array_reasons
  };
  return (dispatch) => {
    return axios.delete(`${apiUrl}/subscription`, {
      data: payload,
      headers: {
        "content-type": "application/json",
        "platform": "Web",
        "device": "customer service",
        "Authorization": authorization,
      }
    }).then(response => {
      console.log('response CANCEL subscription a 30 dias.', response);
      if (response.data.success) fetchStatusActions("success", dispatch, response.data, 'cancel_subscription_30_days', 'Cancelacion de Suscripcion a 30 Dias')
      else fetchStatusActions("error", dispatch, response.data, 'cancel_subscription_30_days', 'Cancelacion de Suscripcion a 30 Dias');
    })
      .catch(error => {
        fetchStatusActions("error", dispatch, error, 'cancel_subscription_30_days', 'Cancelacion de Suscripcion a 30 Dias');
      });
  };
};
export const sendEmailCancelSubscription = (email, subscription) => {
  store.dispatch({
    type: TYPE.LOADING_CANCEL_SUBSCRIPTION_30_DAYS
  })
  //const authorization = 'token ' + token;
  return (dispatch) => {
    return axios.post(`${apiUrl}/subscription/email`, {
      "email": email,
      "subscription": subscription
    }, {
      headers: {
        "content-type": "application/json",
        "platform": "Web",
        "device": "customer service"
      }
    }).then(response => {
      if (response.data.success) fetchStatusActions("success", dispatch, response.data, 'send_email_cancel_subscription', 'Envio de Mail de Cancelacion de Suscripcion')
      else fetchStatusActions("error", dispatch, response.data, 'send_email_cancel_subscription', 'Envio de Mail de Cancelacion de Suscripcion');
    })
      .catch(error => {
        fetchStatusActions("error", dispatch, error, 'send_email_cancel_subscription', 'Envio de Mail de Cancelacion de Suscripcion');
      });
  };
};
export const changePlan = (token, subscription) => {
  store.dispatch({
    type: TYPE.LOADING_CHANGE_PLAN
  })
  const authorization = 'token ' + token;
  return (dispatch) => {
    return axios.post(`${apiUrl}/subscription/plan`, {
      "subscription": subscription
    }, {
      headers: {
        "content-type": "application/json",
        "platform": "Web",
        "device": "customer service",
        "Authorization": authorization
      }
    }).then(response => {
      if (response.data.success) fetchStatusActions("success", dispatch, response.data, 'changePlan', 'Cambio de Plan a Anual')
      else fetchStatusActions("error", dispatch, response.data, 'changePlan', 'Cambio de Plan a Anual');
    })
      .catch(error => {
        fetchStatusActions("error", dispatch, error, 'changePlan', 'Cambio de Plan a Anual');
      });
  };
};
export const changeEmail = (token, email) => {
  store.dispatch({
    type: TYPE.LOADING_CHANGE_EMAIL
  })
  const authorization = 'token ' + token;
  const data = {
    "email": email
  };
  return (dispatch) => {
    return axios.put(`${apiUrl}/user/email`, data, {
      headers: {
        "content-type": "application/json",
        "Authorization": authorization,
        "platform": "Web",
        "device": "customer service"
      }
    }).then(response => {
      if (response.data.success) fetchStatusActions("success", dispatch, response.data.data, 'change_email', 'Cambio de Email')
      else fetchStatusActions("error", dispatch, response.data.data, 'change_email', 'Cambio de Email');
    })
      .catch(error => {
        fetchStatusActions("error", dispatch, error, 'change_email', 'Cambio de Email');
      });
  };
};
export const fetchStatusActions = (status, dispatch, data, type, from) => {
  if (status === "success") {
    dispatch({
      type: TYPE.SHOW_STATUS_ACTIONS,
      payload: { status, type, from, statusCode: 'Proceso exitoso', message: data }
    })
  } else if (status === "error") {
    const error = data;
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      dispatch({
        type: TYPE.SHOW_STATUS_ACTIONS,
        payload: { status, type, from, statusCode: 'Error ' + error.response.status, message: error.response.data.detail }
      })
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      dispatch({
        type: TYPE.SHOW_STATUS_ACTIONS,
        payload: { status, type, from, statusCode: 'Error', message: error.request }
      })

    } else {
      // Something happened in setting up the request that triggered an Error
      dispatch({
        type: TYPE.SHOW_STATUS_ACTIONS,
        payload: { status, type, from, statusCode: 'Error', message: error.message }
      })
    }
  }


}