import "../index.css";
import "./ToDoList.css";
import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import StatusActions from "./StatusActions";
import ListSubscriptions from "./listSubscriptions";
import Preloader from "./Preloader";

class Actions extends Component {
  state = {
    formValue: {
      emailToChange: "",
      emailToCancelSubscription: "",
      reasonToChange: "",
      reasonToChangeDefinitely: "",
      reasonToChange30days: "",
      idSubscription: "",
      idSubscriptionToChangePlan: "",
      safetypay_country: "",
      safetypay_paydate: "",
      safetypay_price: "",
    }
  };

  componentWillMount() {
    //this.props.fetchToken();
  }

  componentDidMount() {
  }
  componentDidUpdate() {

  }
  handleCancelSubscription = (event) => {
    event.preventDefault();
    const { user, cancelSubscription } = this.props;
    const { formValue } = this.state;
    cancelSubscription(user.token, formValue.reasonToChange);
  }
  handleResetPassword = (event) => {
    event.preventDefault();
    const { user, resetPassword } = this.props;
    resetPassword(user.token);
  }

  handleCancelSubscriptionDefinitely = (event) => {
    event.preventDefault();
    const { user, cancelSubscriptionDefinitely } = this.props;
    const { formValue } = this.state;
    cancelSubscriptionDefinitely(user.token, formValue.reasonToChangeDefinitely);
  }
  handleCancelSubscription30days = (event) => {
    event.preventDefault();
    const { user, cancelSubscription30days } = this.props;
    cancelSubscription30days(user.token);
  }
  handleFormSubmitChangeEmail = (event) => {
    event.preventDefault();
    const { user, changeEmail } = this.props;
    const { formValue } = this.state;
    changeEmail(user.token, formValue.emailToChange);
  }
  handleFormSubmitCancelSubscriptionEmail = (event) => {
    event.preventDefault();
    const { sendEmailCancelSubscription } = this.props;
    const { formValue } = this.state;
    sendEmailCancelSubscription(formValue.emailToCancelSubscription, formValue.idSubscription);
  }
  handleFormSubmitChangePlan = (event) => {
    event.preventDefault();
    const { changePlan, user } = this.props;
    const { formValue } = this.state;
    changePlan(user.token, formValue.idSubscriptionToChangePlan);
  }
  handleInputChange = event => {
    //event.persist();
    const { formValue } = this.state;
    var tempObjetct = {};
    tempObjetct[event.target.id] = event.target.value;
    this.setState({ formValue: { ...formValue, ...tempObjetct } });
  };
  handleSelectChange = event => {
    var tempObjetct = {};
    tempObjetct['idSubscription'] = event.target.value;
    this.setState({ formValue: { ...this.state.addFormValue, ...tempObjetct } });
  }
  handleSelectChangePlan = event => {
    var tempObjetct = {};
    tempObjetct['idSubscriptionToChangePlan'] = event.target.value;
    this.setState({ formValue: { ...this.state.addFormValue, ...tempObjetct } });

  }
  renderActions(showStatus, showLoading) {
    const { statusActions, subscription } = this.props;
    const { formValue } = this.state;
    return (
      <div>
        <table>
          <thead>
            <tr>
              <th colSpan="4">Acciones</th>
              <th></th>
            </tr>
          </thead>
          <tbody>

            <tr>
              <td>Cambiar Email</td>
              <td>
                <form onSubmit={this.handleFormSubmitChangeEmail}>
                  <div className="input-field">
                    <i className="material-icons prefix">email</i>
                    <input
                      value={formValue.emailToChange}
                      onChange={this.handleInputChange}
                      id="emailToChange"
                      type="email"
                      className="validate"
                    />
                    <label htmlFor="emailToChange">Email</label>
                    <span className="helper-text" data-error="No es un email correcto!" data-success="Correcto!"></span>
                  </div>
                  <div className="button-field">
                    <button className="btn waves-effect waves-light">OK<i className="material-icons right">send</i></button>
                  </div>
                </form>
              </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Resetear Contraseña</td>
              <td><button className="btn waves-effect waves-light" onClick={this.handleResetPassword}>Eject<i className="material-icons right">eject</i></button></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>

            <tr>
              <td>Cancelar Suscripción</td>
              <td>
                <form onSubmit={this.handleCancelSubscription}>
                  <div className="input-field">
                    <i className="material-icons prefix">sticky_note_2</i>
                    <input
                      value={formValue.reasonToChange}
                      onChange={this.handleInputChange}
                      id="reasonToChange"
                      type="text"
                      className="validate"
                    />
                    <label htmlFor="reasonToChange">Razones (separadas por coma)</label>
                    <span className="helper-text" data-error="No es una razón valida!" data-success="Correcto!"></span>
                  </div>
                  <div className="button-field">
                    <button className="btn waves-effect waves-light red">Eject<i className="material-icons right">eject</i></button>
                  </div>
                </form>
              </td>
              {/* <td><button className="btn waves-effect waves-light red" onClick={this.handleCancelSubscription}>Eject<i className="material-icons right">eject</i></button></td> */}
              <td></td>
              <td></td>
              <td></td>
            </tr>

            <tr>
              <td>Cancelar definitivamente la Suscripción</td>
              <td>
                <form onSubmit={this.handleCancelSubscriptionDefinitely}>
                  <div className="input-field">
                    <i className="material-icons prefix">sticky_note_2</i>
                    <input
                      value={formValue.reasonToChangeDefinitely}
                      onChange={this.handleInputChange}
                      id="reasonToChangeDefinitely"
                      type="text"
                      className="validate"
                    />
                    <label htmlFor="reasonToChangeDefinitely">Razones (separadas por coma)</label>
                    <span className="helper-text" data-error="No es una razón valida!" data-success="Correcto!"></span>
                  </div>
                  <div className="button-field">
                    <button className="btn waves-effect waves-light red">Eject<i className="material-icons right">eject</i></button>
                  </div>
                </form>
              </td>
              {/* <td><button className="btn waves-effect waves-light red" onClick={this.handleCancelSubscriptionDefinitely}>Eject<i className="material-icons right">eject</i></button></td> */}
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Cancelar Suscripción en 30 dias</td>
              <td>
                <form onSubmit={this.handleCancelSubscription30days}>
                  <div className="input-field">
                    <i className="material-icons prefix">sticky_note_2</i>
                    <input
                      value={formValue.reasonToChange30days}
                      onChange={this.handleInputChange}
                      id="reasonToChange30days"
                      type="text"
                      className="validate"
                    />
                    <label htmlFor="reasonToChange30days">Razones (separadas por coma)</label>
                    <span className="helper-text" data-error="No es una razón valida!" data-success="Correcto!"></span>
                  </div>
                  <div className="button-field">
                    <button className="btn waves-effect waves-light red">Eject<i className="material-icons right">eject</i></button>
                  </div>
                </form>
              </td>
              {/* <td><button className="btn waves-effect waves-light orange" onClick={this.handleCancelSubscription30days}>Eject<i className="material-icons right">eject</i></button></td> */}
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
        {subscription !== "" && subscription.subscriptions !== undefined && subscription.subscriptions.length > 0 ?
          (
            <div>
              <table>
                <thead>
                  <tr>
                    <th>Enviar email de cancelacion</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <form onSubmit={this.handleFormSubmitCancelSubscriptionEmail}>
                        <div className="input-field">
                          <i className="material-icons prefix">email</i>
                          <input
                            value={formValue.emailToCancelSubscription}
                            onChange={this.handleInputChange}
                            id="emailToCancelSubscription"
                            type="email"
                            className="validate"
                          />
                          <label htmlFor="emailToCancelSubscription">Email</label>
                          <span className="helper-text" data-error="No es un email correcto!" data-success="Correcto!"></span>
                        </div>
                        <ListSubscriptions value={formValue.idSubscription} handleSelectChange={this.handleSelectChange} subscriptions={subscription} />
                        <div className="button-field">
                          <button className="btn waves-effect waves-light">OK<i className="material-icons right">send</i></button>
                        </div>
                      </form>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table>
                <thead>
                  <tr>
                    <th>Cambio de plan</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <form onSubmit={this.handleFormSubmitChangePlan}>
                        <ListSubscriptions value={formValue.idSubscriptionToChangePlan} handleSelectChange={this.handleSelectChangePlan} subscriptions={subscription} />
                        <div className="button-field">
                          <button className="btn waves-effect waves-light">OK<i className="material-icons right">send</i></button>
                        </div>
                      </form>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>) : <div />
        }
        <table>
          <tbody>
            <tr>
              <td colSpan="5">{showStatus ? <StatusActions statusActions={statusActions} /> :
                (showLoading ? <div className="row center-align">
                  <div className="col s4 offset-s4">
                    <Preloader />
                  </div>
                </div> : <div />)
              }</td>
            </tr>
          </tbody>
        </table>
      </div>

    );
  }
  render() {
    const { statusActions, user } = this.props;
    const isThereUser = user.token !== undefined && user.token !== null ? true : false;
    if (isThereUser && !statusActions.isFetching && !statusActions.isFetched) { return this.renderActions(false, false) }
    else if (isThereUser && statusActions.isFetching) { return this.renderActions(false, true) }
    else if (isThereUser && statusActions.isFetched) { return this.renderActions(true, false) }
    else { return (<div></div>); }
  }
}

const mapStateToProps = ({ statusActions, user, subscription }) => {
  return {
    statusActions,
    user,
    subscription
  };
};

export default connect(mapStateToProps, actions)(Actions);